'use client';

// Modules
import { Suspense, useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { useParams, usePathname } from 'next/navigation';
import classNames from 'classnames';

// Components
import { BtnHamburger } from './BtnHamburger';
import { HvacLogo } from '../logos/HvacLogo';
import { ChatButton } from '../atoms/Button';
import HvacLogoStatic from '../logos/HvacLogo/HvacLogoStatic';

// Styles
import styles from './SiteNav.module.css';
import { useIsDarkVariantPage } from '@/hooks/useIsDarkVariantPage';

/**
 * This is the nav component that allows users to access all hub pages from everywhere on the site.
 * It also holds our dark/light toggle switch and social media accounts.
 * */
const SiteNav = () => {
	const [navOpen, setNavOpen] = useState(false);
	const [navClasses, setNavClasses] = useState<string>(''); // Define state for nav classes
	const [navDarkVariant, setNavDarkVariant] = useState(false);
	const navRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const html = document.getElementsByTagName('html')[0];

		if (navOpen) {
			html.classList.add('lock-scroll--nav');
		} else {
			html.classList.remove('lock-scroll--nav');
		}
		return (): void => {
			html.classList.remove('lock-scroll--nav');
		};
	}, [navOpen]);

	const pathname = usePathname();

	const params = useParams();
	const digitalPost =
		params && Object.prototype.hasOwnProperty.call(params, 'subtaskId');

	const determineActiveLink = (href: string) => {
		return pathname?.startsWith(href);
	};

	const navLinks = [
		{
			href: '/replace',
			label: 'Replace my HVAC',
			testId: 'replace',
		},
		{
			href: '/repair',
			label: 'Repair my HVAC',
			testId: 'repair',
		},
		{
			href: '/tools',
			label: 'Calculators',
			testId: 'calculators',
		},
		{
			href: '/expert-advice',
			label: 'Learn about HVAC',
			testId: 'learn',
		},
		{
			href: '/about-us',
			label: 'About Us',
			testId: 'about-us',
			styles: 'xl:hidden',
		},
	];

	const pageDarkVariant = useIsDarkVariantPage();

	useEffect(() => {
		if (!pageDarkVariant) return;

		const heroHeight = 500;
		const handleResize = () => {
			const scrollDepth = window.scrollY;
			if (navRef.current) {
				updateNavStyles(scrollDepth);
			}
		};

		const handleScroll = () => {
			const scrollDepth = window.scrollY;
			updateNavStyles(scrollDepth);
		};

		const updateNavStyles = (scrollDepth: number) => {
			const navHeight = navRef.current ? navRef.current.clientHeight : 84;

			let newNavClasses = '';
			let darkTemp = false;

			if (scrollDepth > heroHeight) {
				newNavClasses =
					'sticky top-0 bg-white/80 backdrop-blur-sm backdrop-filter';
				darkTemp = false;
			} else if (scrollDepth > navHeight && scrollDepth < heroHeight) {
				newNavClasses = 'sticky -top-28';
				darkTemp = false;
			} else {
				newNavClasses = 'static';
				if (pageDarkVariant) {
					darkTemp = true;
				}
			}

			setNavClasses(newNavClasses);
			setNavDarkVariant(darkTemp);
		};

		window.addEventListener('resize', handleResize);
		window.addEventListener('scroll', handleScroll);

		// Initial update
		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
			window.removeEventListener('scroll', handleScroll);
		};
	}, [pageDarkVariant]);

	return (
		<header
			ref={navRef}
			className={classNames(
				'w-full transition-all duration-300 ease-linear print:!hidden',
				styles['header'],
				{
					[styles['nav-is-visible']]: navOpen,
				},
				{
					'sticky top-0 bg-white/80 backdrop-blur-sm backdrop-filter':
						!pageDarkVariant,
				},
				{
					[styles['nav-dark-variant']]: navDarkVariant && pageDarkVariant,
				},
				navClasses,
			)}
		>
			<div
				className="relative z-10 flex w-full max-w-7xl items-center justify-between gap-4 py-4 pl-4
					lg:px-6 xl:mx-auto xl:gap-0 xl:pt-4 2xl:px-0"
			>
				<Link href={'/'}>
					<Suspense fallback={<HvacLogoStatic className="h-6 xl:h-9" />}>
						<HvacLogo
							hoverColor={
								navDarkVariant && pageDarkVariant && !navOpen ? '#fff' : '#000'
							}
							className={'h-6 xl:h-9'}
						/>
					</Suspense>
				</Link>
				{!digitalPost && (
					<>
						<div id="nav-drawer" className={classNames(styles['nav-drawer'])}>
							<nav className={classNames(styles['nav-pages'])}>
								<ul className="flex flex-col gap-6 xl:flex-row">
									{navLinks.map((link) => (
										<li
											data-testid={`nav-link-${link.testId}`}
											key={link.label}
											className={link.styles}
										>
											<Link
												href={link.href}
												onClick={() => setNavOpen(false)}
												data-label={link.label}
												className={classNames(
													'text-black hover:text-sky-800',
													// all after: pseudo-element styles are to prevent link text padding "shoving" other nav links around when active link goes bold
													`after:invisible after:block after:h-0 after:overflow-hidden after:font-bold
													after:text-transparent after:content-[attr(data-label)]`,
													{
														'font-bold text-black': determineActiveLink(
															`${link.href}`,
														),
													},
												)}
											>
												{link.label}
											</Link>
										</li>
									))}
								</ul>
							</nav>
						</div>
						<div className="flex items-center gap-4">
							<ChatButton
								navOpen={navOpen}
								navDarkVariant={navDarkVariant && pageDarkVariant}
							/>
							<BtnHamburger
								setNavOpen={setNavOpen}
								navOpen={navOpen}
								navDarkVariant={navDarkVariant && pageDarkVariant}
								className="mr-4 lg:mr-0 xl:hidden"
							/>
						</div>
					</>
				)}
			</div>
		</header>
	);
};

export default SiteNav;
